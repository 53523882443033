// Load Styles
import '../scss/main.scss';

// Load Bootstrap init
import { initBootstrap } from './bootstrap.js';
import PackageJson from '../../package.json';
import {
    LINKEDIN_SUMMARY,
    LINKEDIN_TITLE,
    TWITTER_MESSAGE,
    WHATSAPP_MESSAGE
} from './constants.js';

// Loading bootstrap with optional features
initBootstrap({
    tooltip: true,
    popover: true,
    toasts: true
});

const signinButton = document.getElementById('signin-button');
const signupButton = document.getElementById('singup-button');
const version = document.getElementById('version');
const likeAndShareButton = document.getElementById('lnsbutton');

signinButton.href = process.env.EXAMINATIONS_ROOT;
signupButton.href = `${process.env.EXAMINATIONS_ROOT}/sign-up`;
version.textContent = `v. ${String(PackageJson.version)}`;

export function mobileDetection(navigator) {
    let browser;
    let system;
    let isMobile;

    const sUsrAg = navigator.userAgent;

    if (sUsrAg.indexOf('Chrome') > -1) {
        browser = 'CHROME';
    } else if (
        sUsrAg.indexOf('Safari') > -1 &&
        !sUsrAg.match(/\b(\w*CriOS\w*)\b/g) &&
        !sUsrAg.match(/\b(\w*FxiOS\w*)\b/g)
    ) {
        browser = 'SAFARI';
    } else {
        browser = 'OTHER';
    }

    if (/android/i.test(navigator.userAgent)) {
        system = 'ANDROID';
    } else if (/iPad|iPhone|iPod.+AppleWebKit/i.test(navigator.userAgent) && !window.MSStream) {
        system = 'IOS';
    } else {
        system = 'OTHER';
    }

    if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ) {
        isMobile = true;
    } else {
        isMobile = false;
    }

    const isStandalone = !!navigator.standalone;

    return {
        browser,
        system,
        isMobile,
        isStandalone
    };
}

function socialWindow(url) {
    const { isMobile } = mobileDetection(navigator);
    if (isMobile) window.open(url);
    const left = (screen.width - 570) / 2;
    const top = (screen.height - 570) / 2;
    const params =
        'menubar=no,toolbar=no,status=no,width=570,height=570,top=' + top + ',left=' + left;
    window.open(url, 'NewWindow', params);
}

function setShareLinks() {
    const fbLinks = document.getElementsByClassName('fa-facebook-f');
    const twLinks = document.getElementsByClassName('fa-twitter');
    const inLinks = document.getElementsByClassName('fa-linkedin');
    const whLinks = document.getElementsByClassName('fa-whatsapp');

    if (fbLinks.length > 0) {
        for (let i = 0; i < fbLinks.length; i++) {
            fbLinks[i].onclick = function () {
                const url = `https://www.facebook.com/sharer.php?u=${process.env.SOCIAL_SHAREABLE_URL}`;
                socialWindow(url);
            };
        }
    }

    if (twLinks.length > 0) {
        for (let i = 0; i < twLinks.length; i++) {
            twLinks[i].onclick = function () {
                const url = `https://twitter.com/intent/tweet?url=${
                    process.env.SOCIAL_SHAREABLE_URL
                }&text=${encodeURIComponent(TWITTER_MESSAGE)}`;
                socialWindow(url);
            };
        }
    }

    if (inLinks.length > 0) {
        for (let i = 0; i < inLinks.length; i++) {
            inLinks[i].onclick = function () {
                const url = `https://www.linkedin.com/shareArticle?mini=true&url=${
                    process.env.SOCIAL_SHAREABLE_URL
                }&title=${LINKEDIN_TITLE}&summary=${encodeURIComponent(LINKEDIN_SUMMARY)}`;
                socialWindow(url);
            };
        }
    }

    if (whLinks.length > 0) {
        for (let i = 0; i < whLinks.length; i++) {
            whLinks[i].onclick = function () {
                const url = `https://wa.me/?text=${encodeURIComponent(WHATSAPP_MESSAGE)}${
                    process.env.SOCIAL_SHAREABLE_URL
                }`;
                socialWindow(url);
            };
        }
    }
}

const showSocialsList = (e) => {
    const socials = document.getElementById('socials');
    if (!socials.classList.contains('show')) {
        socials.classList.add('show');
        e.stopPropagation();
        return hideOnClickOutside(socials);
    }
    return socials.classList.remove('show');
};

if (likeAndShareButton) {
    likeAndShareButton.onclick = showSocialsList;
}

setShareLinks();

function hideOnClickOutside(element) {
    const outsideClickListener = (event) => {
        if (!element.contains(event.target) && isVisible(element)) {
            element.classList.remove('show');
            removeClickListener();
        }
    };

    const removeClickListener = () => {
        document.removeEventListener('click', outsideClickListener);
    };

    document.addEventListener('click', outsideClickListener);
}

const isVisible = (elem) =>
    !!elem && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
